import React, { useEffect, useState } from 'react';

const parse = (value: string) => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

const useLocalStorage = <S,>(key: string): [S, React.Dispatch<React.SetStateAction<S>>] => {
    const [state, setState] = useState<S>(typeof window !== 'undefined' ? parse(localStorage.getItem(key) as string) : null);

    useEffect(() => {
        if (state) {
            localStorage.setItem(key, JSON.stringify(state));
        }
    }, [key, state]);

    return [state, setState];
};

export default useLocalStorage;
