// FIXME(LY) Application types should go into the SDK. Leaving it here now for speed
import { AllFields } from '@hooks/wizard/types';
import { LegalEntityType, LegalEntityTypeOptions } from '@utils/static-options/entityType';
import { getStateOption } from '@utils/FormUtils';
import dayjs from 'dayjs';
import MONTHS from '@utils/static-options/month';

// FIXME(LY) Using string to allow for objects and arrays of objects like contact.FirstName
const mapApplicationFields = (name: (keyof AllFields) | string, value: any, values: AllFields): UpdateApplicationRequest => {
    switch (name) {
        case 'avgTransactionSize':
            return { processingProfile: { averageTicket: +value } };
        case 'businessAddr1':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { line1: value } }, legalEntity: { address: { line1: value } }
                } : {
                    business: { address: { line1: value } }
                }
        case 'businessAddr2':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { line2: value } }, legalEntity: { address: { line2: value } }
                } : {
                    business: { address: { line2: value } }
                }
        case 'businessCity':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { city: value } }, legalEntity: { address: { city: value } }
                } : {
                    business: { address: { city: value } }
                }
        case 'businessEmail':
            return { business: { email: value } };
        case 'businessPhone':
            return { business: { phone: value.replace(/\s/g, '') } };
        case 'businessState':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { state: value } }, legalEntity: { address: { state: value } }
                } : {
                    business: { address: { state: value } }
                }
        case 'businessTaxPayMethod':
            break;
        case 'businessZipCode':
            return values.legalIsPhysicalAddress !== 'no' ?
                {
                    business: { address: { postalCode: value } }, legalEntity: { address: { postalCode: value } }
                } : {
                    business: { address: { postalCode: value } }
                }
        case 'contact.FirstName':
            return { contact: { firstName: value, controlProng: true, authorizedSigner: true } };  // hard-coding, we assume the "contact" will always be control prong & authorized signer
        case 'contact.LastName':
            return { contact: { lastName: value } };
        case 'contact.ContactNumber':
            return { contact: { phone: value.replace(/\s/g, '') } };
        case 'contact.SSN':
            return { contact: { ssn: value } };
        case 'contact.DateOfBirth': {
            const [month, day, year] = value.split('/');
            return { contact: { dob: `${year}-${month}-${day}` } };
        }
        case 'contact.Email':
            return { contact: { email: value } };
        case 'contact.Title':
            return { contact: { title: value } };
        case 'contact.IsOwner':
            return { contact: { isOwner: value === 'yes' } };
        case 'contact.PercentOwnership':
            return { contact: { percentOwnership: +value } };
        case 'contact.isPrimaryLocSameAsBusiness':
            return value === 'yes' ? {
                contact: {
                    address: {
                        line1: values.businessAddr1,
                        city: values.businessCity,
                        state: values.businessState?.value as any,
                        postalCode: values.businessZipCode,
                        ...(values?.businessAddr2?.length ? { line2: values.businessAddr2 } : {})
                    }
                }
            } : {
                contact: { address: {} } // Need to fix Account API not saving Address as {}
            };
        case 'contact.Addr1':
            return { contact: {address: { line1: value } } };
        case 'contact.Addr2':
            return { contact: {address: { line2: value } } };
        case 'contact.City':
            return { contact: {address: { city: value } } };
        case 'contact.State':
            return { contact: {address: { state: value } } };
        case 'contact.ZipCode':
            return { contact: {address: { postalCode: value } } };
        case 'dateOfEstb': {
            const [month, day, year] = value.split('/');
            return { legalEntity: { dateOfIncorporation: `${year}-${month}-${day}` } };
        }
        case 'dbaName':
            return { business: { dba: value } };
        case 'entityType':
            return { legalEntity: { type: value } };
        case 'expectedMonthlyCCVolume':
            return { processingProfile: { averageMonthlyCardVolume: +value } };
        case 'high_ticket_volume':
            return { processingProfile: { highestTicket: +value } };
        case 'internetPercentage':
            return { processingProfile: { acceptanceMethods: { internet: +value } } };
        case 'isCurrentlyAcceptingCreditCards':
            return value === 'yes' ? {
                processingProfile: {
                    averageMonthlyCardVolume: +(values.expectedMonthlyCCVolume || 0),
                    currentlyAcceptingCards: true,
                }
            } : {
                processingProfile: {
                    averageMonthlyCardVolume: 0,
                    currentlyAcceptingCards: false,
                }
            };
        case 'isSeasonal':
            return { processingProfile: { seasonal: value === 'yes' } };
        case 'keyedPercentage':
            return { processingProfile: { acceptanceMethods: { keyed: +value } } };
        case 'legalAddr1':
            return { legalEntity: { address: { line1: value } } };
        case 'legalAddr2':
            return { legalEntity: { address: { line2: value } } };
        case 'legalCity':
            return { legalEntity: { address: { city: value } } };
        case 'legalIsPhysicalAddress':
            return value === 'yes' ? {
                legalEntity: {
                    address: {
                        line1: values.businessAddr1,
                        city: values.businessCity,
                        state: values.businessState?.value as USState,
                        postalCode: values.businessZipCode,
                        ...(values?.businessAddr2?.length ? { line2: values.businessAddr2 } : {})
                    }
                }
            } : {
                legalEntity: {
                    address: {
                        line1: values.legalAddr1,
                        city: values.legalCity,
                        state: values.legalState?.value as USState,
                        postalCode: values.legalZipCode,
                        ...(values?.legalAddr2?.length ? { line2: values.legalAddr2 } : {})
                    }
                }
            };
        case 'legalName':
            return { legalEntity: { name: value } };
        case 'legalState':
            return { legalEntity: { address: { state: value } } };
        case 'legalZipCode':
            return { legalEntity: { address: { postalCode: value } } };
        case 'motoPercentage':
            return { processingProfile: { acceptanceMethods: { moto: +value } } };
        case 'primaryAccountBankName':
            return { banking: { bankName: value } };
        case 'primaryBankAcHolderFullName':
            return { banking: { accountHolderName: value } };
        case 'primaryBankAcNumber':
            return { banking: { depositAccount: value } };
        case 'primaryBankRoutingNumber':
            return { banking: { depositRouting: value } };
        case 'seasonalFrom':
            return { processingProfile: { seasonalFrom: +value } };
        case 'seasonalTo':
            return { processingProfile: { seasonalTo: +value } };
        case 'swipedPercentage':
            return { processingProfile: { acceptanceMethods: { swiped: +value } } };
        case 'tin':
            return { legalEntity: { tin: value } };
        case 'targetDate':
            break;
        case 'locationCount':
            break;
        case 'businessUrl':
            return { business: { websiteUrl: value } };
        case 'marketingWebsite':
            break;
        case 'marketingFile':
            break;
        case 'transactionCompletePeriod':
            break;
        case 'daysToReturnOrCancel':
            break;
        case 'isGSDeliveryAfterTxnDay':
            break;
        case 'delivery_immediate_Percentage':
            break;
        case 'delivery_1_3_DaysPercentage':
            break;
        case 'delivery_4_7_DaysPercentage':
            break;
        case 'delivery_8_14_DaysPercentage':
            break;
        case 'delivery_15_30_DaysPercentage':
            break;
        case 'delivery_Over_30_DaysPercentage':
            break;
        case 'recurringMonthly':
            break;
        case 'billingPeriod':
            break;
        case 'entireCreditCardCollet':
            break;
        case 'PCICompliant':
            break;
        case 'contractSign':
            break;
        case 'contractFiles':
            break;
        case 'howCustomerOrder':
            break;
        case 'vendorPurchaseAddress':
            break;
        case 'serviceForCardHolder':
            break;
        case 'processingStatement1':
            break;
        case 'processingStatement2':
            break;
        case 'processingStatement3':
            break;
        case 'cnpAgreementRequired':
            break;
        case 'primaryOwnerFirstName':
            break;
        case 'primaryAcFile':
            break;
        case 'extraFiles':
            break;
        case 'maskedPrimaryBankAcNumber':
            break;
        default: {
            return {};
        }
    }
    return {};
}

// FIXME(LY) Using string to allow for objects and arrays of objects like people.1.FirstName
const mapOwnerFields = (name: string, value: any, values: AllFields): UpdateOwnerRequest => {
    const [, i, field] = name.split('.');
    const id = values.people?.at(+i)?.id;
    switch (field) {
        case 'FirstName':
            return { id, firstName: value, controlProng: false };  // hard-coding, we assume the "contact" will always be control prong, not those which are passed as "additional owners"
        case 'LastName':
            return { id, lastName: value };
        case 'ContactNumber':
            return { id, phone: value.replace(/\s/g, '') };
        case 'SSN':
            return { id, ssn: value };
        case 'DateOfBirth': {
            const [month, day, year] = value.split('/');
            return { id, dob: `${year}-${month}-${day}` };
        }
        case 'Email':
            return { id, email: value };
        case 'Title':
            return { id, title: value };
        case 'PercentOwnership':
            return { id, percentOwnership: +value };
        case 'isPrimaryLocSameAsBusiness':
            return value === 'yes' ? {
                id,
                address: {
                    line1: values.businessAddr1,
                    city: values.businessCity,
                    state: values.businessState?.value as any,
                    postalCode: values.businessZipCode,
                    ...(values?.businessAddr2?.length ? { line2: values.businessAddr2 } : {})
                }
            } : {
                id,
                address: {} // Need to fix Account API not saving Address as {}
            };
        case 'Addr1':
            return { id, address: { line1: value } };
        case 'Addr2':
            return { id, address: { line2: value } };
        case 'City':
            return { id, address: { city: value } };
        case 'State':
            return { id, address: { state: value } };
        case 'ZipCode':
            return { id, address: { postalCode: value } };
        default:
            return {};
    }
};

export const toAccountAPI = (name: (keyof AllFields) | string, value: any, values: AllFields) => name.startsWith('people')
    ? ({ _type: 'owner' as const, ...mapOwnerFields(name, value, values) })
    : ({ _type: 'application' as const, ...mapApplicationFields(name, value, values) });

export const transformToFormFields = (values: AccountApplication): Partial<AllFields> => {
    const legalIsPhysicalAddress = values?.business?.address?.line1 === values?.legalEntity?.address?.line1
        && values?.business?.address?.city === values?.legalEntity?.address?.city
        && values?.business?.address?.line2 === values?.legalEntity?.address?.line2
        && values?.business?.address?.postalCode === values?.legalEntity?.address?.postalCode
    return {
        dbaName: values?.business?.dba,
        businessPhone: values?.business?.phone,
        businessEmail: values?.business?.email,
        businessUrl: values.business.websiteUrl,
        businessAddr1: values?.business?.address?.line1 || "",
        businessAddr2: values?.business?.address?.line2,
        businessCity: values?.business?.address?.city || "",
        businessState: getStateOption(values.business?.address?.state),
        businessZipCode: values?.business?.address?.postalCode || '',
        legalName: values?.legalEntity?.name,
        tin: values?.legalEntity?.tin?.toString() || "",
        legalIsPhysicalAddress: legalIsPhysicalAddress ? "yes" : "no",
        legalAddr1: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.line1,
        legalAddr2: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.line2,
        legalCity: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.city,
        legalState: legalIsPhysicalAddress ? undefined : getStateOption(values.legalEntity.address?.state),
        legalZipCode: legalIsPhysicalAddress ? undefined : values.legalEntity.address?.postalCode,
        entityType: values?.legalEntity?.type && LegalEntityTypeOptions?.find((entity: { label: string, value: string }) => entity.value === values.legalEntity.type),
        dateOfEstb: values?.legalEntity?.dateOfIncorporation && dayjs(values?.legalEntity?.dateOfIncorporation, { format: "YYYY-MM-DD" }).format("MM/DD/YYYY"),
        people: values.people?.length ? values?.people?.map?.((person) => {
            const sameAsPhysical = values?.business?.address?.line1 === person?.address?.line1
                && values?.business?.address?.city === person?.address?.city
                && values?.business?.address?.line2 === person?.address?.line2
                && values?.business?.address?.postalCode === person?.address?.postalCode
            return ({
                isPrimaryLocSameAsBusiness: sameAsPhysical ? 'yes' : 'no',
                Addr1: !sameAsPhysical && person?.address?.line1 ? person?.address?.line1 : "",
                Addr2: !sameAsPhysical && person?.address?.line2 ? person?.address?.line2 : "",
                City: !sameAsPhysical && person?.address?.city ? person?.address?.city : "",
                ContactNumber: person?.phone || "",
                DateOfBirth: person?.dob ? dayjs(person.dob, { format: "YYYY-MM-DD" }).format("MM/DD/YYYY") : "",
                Email: person?.email || "",
                FirstName: person?.firstName || "",
                id: person.id || "",
                SSN: undefined,
                LastName: person.lastName || "",
                PercentOwnership: person.percentOwnership?.toString() || "",
                State: !sameAsPhysical && getStateOption(person.address?.state) || undefined,
                Title: person.title ? { label: person.title, value: person.title } : undefined,
                ZipCode: !sameAsPhysical && person?.address?.postalCode ? person?.address?.postalCode : "",
            })
        }) : [],
        primaryAccountBankName: values?.banking?.bankName,
        primaryBankAcHolderFullName: values?.banking?.accountHolderName,
        primaryBankRoutingNumber: values?.banking?.depositRouting,
        contact: {
            Addr1: values.contact?.address?.line1 || "",
            Addr2: values.contact?.address?.line2 || "",
            City: values.contact?.address?.city || "",
            State: getStateOption(values?.contact?.address?.state),
            ZipCode: values?.contact?.address?.postalCode || "",
            ContactNumber: values.contact?.phone || "",
            DateOfBirth: values.contact?.dob ? dayjs(values.contact?.dob, { format: "YYYY-MM-DD" }).format("MM/DD/YYYY") : "",
            Email: values?.contact?.email || "",
            FirstName: values?.contact?.firstName || "",
            LastName: values?.contact?.lastName || "",
            Title: values?.contact?.title ? { label: values?.contact?.title, value: values?.contact?.title } : undefined,
            isPrimaryLocSameAsBusiness: (
                values?.business?.address?.line1
                && values?.business?.address?.line1 === values?.contact?.address?.line1
                && values?.business?.address?.city === values?.contact?.address?.city
                && values?.business?.address?.line2 === values?.contact?.address?.line2
                && values?.business?.address?.postalCode === values?.contact?.address?.postalCode
            ) ? 'yes' : 'no'
        },
        swipedPercentage: values?.processingProfile?.acceptanceMethods?.swiped?.toString(),
        keyedPercentage: values?.processingProfile?.acceptanceMethods?.keyed?.toString(),
        motoPercentage: values?.processingProfile?.acceptanceMethods?.moto?.toString(),
        internetPercentage: values?.processingProfile?.acceptanceMethods?.internet?.toString(),
        avgTransactionSize: values?.processingProfile?.averageTicket?.toString(),
        high_ticket_volume: values?.processingProfile?.highestTicket?.toString(),
        expectedMonthlyCCVolume: values?.processingProfile?.averageMonthlyCardVolume?.toString(),
        isCurrentlyAcceptingCreditCards: values?.processingProfile?.currentlyAcceptingCards ? "yes" : "no",
        isSeasonal: values?.processingProfile?.seasonal ? "yes" : "no",
        seasonalFrom: values?.processingProfile?.seasonalFrom ? MONTHS.find((month) => month.value === values.processingProfile.seasonalFrom?.toString()) : undefined,
        seasonalTo: values?.processingProfile?.seasonalTo ? MONTHS.find((month) => month.value === values.processingProfile.seasonalTo?.toString()) : undefined
    }
};

/**
 * Types that make up a full application
 */
export type AccountApplication = {
    business: {
        dba: string;
        sic?: string;
        goodsAndServices?: string;
        address: Address;
        phone: string;
        email: string;
        websiteUrl?: string;
    };
    legalEntity: {
        name: string;
        type: 'Sole Proprietorship' | 'Publicly Traded Partnership' | 'Publicly Traded Limited Liability Company' | 'Private Partnership' | 'Private Corp' | 'Public Corp' | 'Association/Estate/Trust' | 'Tax Exempt Organization' | 'Private Limited Liability Company' | 'International/Organization' | 'Government';
        tin?: string | number;
        address?: Address;
        mailingAddress?: Address;
        dateOfIncorporation: string;
        foreign?: boolean;
    };
    people?: Owner[];
    readonly banking: {
        accountHolderName: string;
        bankName: string;
        depositAccount: string;
        depositRouting: string;
    };
    readonly contact?: Contact;
    readonly files?: File[];
    fileInfo?: Map<string, FileInfo>;
    readonly processingProfile: {
        readonly acceptanceMethods: {
            readonly swiped: number;
            readonly keyed: number;
            readonly moto: number;
            readonly internet: number;
        };
        readonly averageMonthlyCardVolume: number;
        readonly averageTicket: number;
        readonly currentlyAcceptingCards: boolean;
        readonly highestTicket: number;
        readonly seasonal?: boolean;
        readonly seasonalFrom?: number;
        readonly seasonalTo?: number;
        readonly useDiscoverDirect?: boolean;
        readonly discoverId?: number;
        readonly useAmexDirect?: boolean;
        readonly amexEsaNumber?: number;
    };
    readonly pricingCode?: string;
    readonly agreement: {
        readonly timestamp: string;
        readonly ipAddress: string;
        readonly keyedInSignature: string;
    };
};

type Person = {
    readonly salutation?: string;
    readonly firstName?: string;
    readonly middleName?: string;
    readonly lastName?: string;
    readonly address?: Address;
    readonly email?: string;
    readonly phone?: string;
    readonly controlProng?: boolean;
    readonly dob?: string;
    readonly ssn?: string;
}

type Owner = {
    id?: string;
    readonly title?: OwnerTitle;
    readonly percentOwnership?: number;
} & Person;

type Contact = {
    readonly title?: string;
    readonly authorizedSigner?: boolean;
    readonly isOwner?: boolean;
    readonly percentOwnership?: number;
} & Person;

type Address = {
    readonly line1?: string;
    readonly line2?: string;
    readonly city?: string;
    readonly state?: USState;
    readonly postalCode?: string;
}

export const FileTypes = ['Signed MPA', 'Voided Check', 'Current Statement', 'Financials', 'W9', 'Other', 'Internal File', 'Risk/Underwriting', 'Addendum', 'Partner/Referral Agreement', 'Tax Exemption'] as const;
export type FileType = typeof FileTypes[number];

type File = {
    readonly fileType: FileType,
    readonly url: string,
    readonly name: string,
}

type OwnerTitle = 'President' | 'Vice President' | 'Treasurer' | 'Owner' | 'Partner' | 'CEO' | 'Secretary' | 'Director';

type USState = 'AL' | 'AK' | 'AR' | 'AZ' | 'CA' | 'CO' | 'CT' | 'DC' | 'DE' | 'FL' | 'GA' | 'HI' | 'IA' | 'ID'
    | 'IL' | 'IN' | 'KS' | 'KY' | 'LA' | 'MA' | 'MD' | 'ME' | 'MI' | 'MN' | 'MO' | 'MS' | 'MT' | 'NC' | 'ND' | 'NE'
    | 'NH' | 'NJ' | 'NM' | 'NV' | 'NY' | 'OH' | 'OK' | 'OR' | 'PA' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VA'
    | 'VT' | 'WA' | 'WI' | 'WV' | 'WY';

/**
 * Non-optional fields or fields with nested non-optional fields
 */
type partialApplicationRequiredFields = {
    readonly pricingCode: string,
    readonly files?: File[];
};

type PartialApplication = Omit<AccountApplication, 'agreement' | 'pricingCode' | 'files'> & partialApplicationRequiredFields;

/**
 * Types that make up a partial application update request
 */
type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };
type UpdateOwnerRequest = Omit<Partial<Owner>, 'id'> & Pick<Owner, 'id'>;
type UpdateApplicationRequest = Omit<DeepPartial<PartialApplication>, 'externalApplicationId' | 'people' | 'pricingCode' | 'files'>;
export type ApplicationUpdate = ReturnType<typeof toAccountAPI>;

type FileInfo = {
    fileType: FileType,
    key: string,
    name: string,
    contentLength: number
    contentType: string,
}
