import useWizardContext from './wizard/useWizardContext';
import useLocalStorage from './useLocalStorage';
import { AllFields } from './wizard/types';
import { toAccountAPI } from './types';

const useAutoSave = () => {
    const { form, id, onAutoSave = () => null, setIsSaving } = useWizardContext();

    const [, setState] = useLocalStorage<{ [key: string]: any }>(id || 'default');

    const { getValues } = form;

    const handleSave = async ({ name, value }: { name: string, value: any }) => {
        setIsSaving?.(true);
        // FIXME(LY) Would be nice if this was more targeted. Look at dot notations like Account API?
        setState(getValues());
        const patch = toAccountAPI(name as any, value, getValues() as unknown as AllFields);
        if (id && Object.keys(patch).length) {
            await onAutoSave(patch);
        }
        setIsSaving?.(false);
    };

    return { handleSave };
};

export default useAutoSave;
